import { makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getPolicy = async () => {
    const result = await makeGetRequest(API.policy.get);

    return result.data.result.data.items;
};

export const editPolicy = async (data: any) => {
    const result = await makeFormRequest(API.policy.edit, data);

    return result;
};
