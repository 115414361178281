
import Layout from '@/admin/layouts/Default.vue';
import { Component, Vue } from 'vue-property-decorator';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

import ContentModule from '@/admin/store/content';
import SiteModule from '@/admin/store/site';
import InputEntity from '@/lib/formFactory/inputEntity';
import { Input } from '@/lib/formFactory/input.interface';

import Loading from '@/components/Loading.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import Editor from '@/components/modules/Editor.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import { getPolicy, editPolicy } from '@/admin/api/policy';
import { tabsNavInterface } from '@/interfaces/tabs';

@Component({
    components: {
        Layout,
        Loading,
        TitleReturn,
        Editor,
        BtnDefault,
        BtnSubmit,
        TabsNav,
    },
})
export default class OfferComponent extends Vue {
    editorCounter = 0;
    isLoading = true;
    policy: Input | {} = {};
    messages = {
        errorGet: 'Не удалось загрузить Политику конфиденциальности',
        errorEdit: 'Не удалось обновить Политику конфиденциальности',
        successSave: 'Изменения сохранены',
    };

    get tabsNav(): tabsNavInterface {
        return ContentModule.tabsNav;
    }

    get currentTab(): string {
        return (ContentModule.tabsNav as tabsNavInterface).policy.id;
    }

    async save(): Promise<void> {
        SiteModule.SET_IS_BLOCK(true);
        try {
            await editPolicy({ mainText: (this.policy as Input).value });
            ResponseHandlerModule.showNotify({ message: this.messages.successSave, type: 'ok' });
        } catch (e) {
            ResponseHandlerModule.showNotify({ message: this.messages.errorEdit, type: 'fail' });
        }
        SiteModule.SET_IS_BLOCK(false);
    }

    reset(): void {
        this.get();
    }

    async get(): Promise<void> {
        this.isLoading = true;

        try {
            const result = await getPolicy();
            (this.policy as Input).value = result.mainText;
            this.editorCounter++;
        } catch (e) {
            ResponseHandlerModule.showNotify({ message: this.messages.errorGet, type: 'fail' });
        }

        this.isLoading = false;
    }

    updatePolicy(value: string): void {
        (this.policy as Input).value = value;
    }

    async created(): Promise<void> {
        this.policy = new InputEntity('policy').defaultValue;

        this.get();
    }

    beforeDestroy(): void {
        this.isLoading = true;
        this.policy = {};
    }
}
